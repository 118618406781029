import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _74e9a4e0 = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _703d25e9 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _7c6c4cbe = () => interopDefault(import('../pages/portal/_contact/index.vue' /* webpackChunkName: "pages/portal/_contact/index" */))
const _6a8efc09 = () => interopDefault(import('../pages/_request.vue' /* webpackChunkName: "pages/_request" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/privacy-policy",
    component: _74e9a4e0,
    name: "privacy-policy"
  }, {
    path: "/",
    component: _703d25e9,
    name: "index"
  }, {
    path: "/portal/:contact",
    component: _7c6c4cbe,
    name: "portal-contact"
  }, {
    path: "/:request",
    component: _6a8efc09,
    name: "request"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
